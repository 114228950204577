<template>
  <div v-if="reservation">
    <container class="" :padding-top="true">
      <h1 class="text-gray-800 font-semibold text-3xl text-center">{{ reservation.room.building.city }} ({{ reservation.room.building.name }}) </h1>
      <h1 class="text-flamingo-800 font-semibold text-3xl text-center mt-5 "> {{ reservation.room.name }}</h1>
      <div class="text-center">
        <button type="button"
                @click="printReservation"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300
                      shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Imprimer
        </button>
        <button type="button"  v-if="!reservation.canceled_at"
                @click="cancelReservation"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300
                      shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Annuler
        </button>
      </div>
      <div class=" pt-5">
        <div class="md:flex p-1 ">
          <span class="w-1/6 font-semibold">{{ $t('MY_RESERVATIONS.FOR') }} : </span>
          <span class="w-5/6">{{ reservation.title }}</span>
        </div>
        <div v-if="reservation.description" class="md:flex p-1 ">
          <span class="w-1/6 font-semibold">{{ $t('MY_RESERVATIONS.DESCRIPTION') }} : </span>
          <span class="w-5/6">{{ reservation.description }}</span>
        </div>

        <div v-if="reservation.document_url" class="md:flex p-1 ">
          <span class="w-1/6 font-semibold">{{ $t('MY_RESERVATIONS.File') }} : </span>
          <a class=" hover:underline hover:text-flamingo-800" :href="reservation.document_url" target="_blank">
            <font-awesome-icon :icon="['far','file-pdf']" class="ml-0.5 mr-1.5"></font-awesome-icon>
            Fichier
          </a>
        </div>
        <div class="md:flex flex p-1 items-center ">
          <span class="w-1/6 font-semibold">Statut : </span>
          <badge v-if="reservation.status_id == 1" class="rounded-2xl w-1/6" variant="success">{{ $t('MY_RESERVATIONS.CONFIRMED') }}</badge>
          <badge v-else-if="reservation.status_id == 2" class="rounded-2xl w-1/6" variant="failed">{{ $t('MY_RESERVATIONS.REJECTED') }}</badge>
          <badge v-else-if="reservation.status_id == 3" class="rounded-2xl w-1/6" variant="failed">{{ $t('MY_RESERVATIONS.CANCELLED') }}</badge>
          <badge v-else class="rounded-2xl w-3/6 md:w-1/6" variant="secondary">{{ $t('MY_RESERVATIONS.WAITING') }}</badge>
        </div>
        <div v-if="reservation.profile" class="md:flex p-1 ">
          <span class="w-1/6 font-semibold">{{ $t('MY_RESERVATIONS.PROFILE') }} : </span>
          <span class="w-5/6">{{ reservation.profile.name }}</span>
        </div>
      </div>
    </container>
    <div class="h-2 bg-flamingo-800"></div>
    <div class="h-40 bg-flamingo-800 mt-5 mb-5"></div>
    <container class="-mt-40" :padding-top="false">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-x-16">
        <div class="bg-white rounded w-full shadow-md  my-5 ">
          <h2 class=" p-4 text-gray-800 font-semibold text-2xl border-b">
            {{ $t('MY_RESERVATIONS.DETAILS') }}
          </h2>
          <div class="flex border-b">
            <div class="p-5 border-r w-1/2">
              <p class="text-gray-800 font-semibold text-lg"> {{ $t('MY_RESERVATIONS.ARRIVAL') }}</p>
              <p>{{ reservation.date_from_localized }}</p>
            </div>
            <div class="p-5 w-1/2">
              <p class="text-gray-800 font-semibold text-lg"> {{ $t('MY_RESERVATIONS.DEPARTURE') }}</p>
              <p>{{ reservation.date_to_localized }}</p>
            </div>
          </div>
          <div>
            <div class="p-4 text-gray-800 text-lg w-full">
              <span class="font-semibold">Code de confirmation :</span>
              <span class="ml-5">{{ reservation.id }}</span>
            </div>
          </div>
        </div>
        <div class="bg-white rounded w-full shadow-md my-5 ">
          <div class="flex justify-between items-center space-x-3 p-4 border-b">
            <h2 class="text-gray-800 font-semibold text-2xl">
              {{ $t('MY_RESERVATIONS.INFORMATION') }}
            </h2>
            <badge v-if="reservation.is_paid" class="rounded-2xl" variant="success">{{ $t('MY_RESERVATIONS.PAID') }}</badge>
            <badge v-else class="rounded-2xl" variant="secondary">{{ $t('MY_RESERVATIONS.NOT_PAID') }}</badge>
          </div>
          <div class="text-gray-800 font-semibold">
            <div class="md:flex p-5 border-b items-center justify-between ">
              <p class=" text-lg">{{ $t('MY_RESERVATIONS.PRICE') }}</p>
              <p>{{ reservation.price/100 }} €</p>
            </div>
            <div class="md:flex p-5 items-center justify-between ">
              <p class=" text-lg">{{ $t('MY_RESERVATIONS.DEPOSIT') }}</p>
              <p>{{ reservation.deposit/100 }} €</p>
            </div>
            <div class="md:flex p-5 items-center justify-between ">
              <p class=" text-lg">{{ $t('MY_RESERVATIONS.TOTAL_PRICE') }}</p>
              <p>{{ reservation.total_price/100 }} €</p>
            </div>
          </div>
        </div>
        <div class="bg-white rounded w-full shadow-md ">
          <div class="flex justify-between items-center space-x-3 p-4 border-b">
            <h2 class="text-gray-800 font-semibold text-2xl">
              {{ $t('MY_RESERVATIONS.CARETAKER') }}
            </h2>
          </div>
          <div v-for="caretaker of reservation.room.caretakers" :key="caretaker.id" class="text-gray-800 border-b px-3 ">
            <div class="md:flex p-2 items-center justify-between ">
              <p class="font-semibold text-lg">{{ $t('MY_RESERVATIONS.CARETAKER_NAME') }}:</p>
              <p>{{ caretaker.first_name + ' '  + caretaker.last_name }} </p>
            </div>
            <div class="md:flex p-2 items-center justify-between ">
              <p class="font-semibold text-lg">{{ $t('MY_RESERVATIONS.CARETAKER_EMAIL') }}:</p>
              <p>{{ caretaker.email }} </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="reservation.requested_items.length" class="bg-white rounded w-full shadow-md my-5 ">
        <h2 class="text-gray-800 font-semibold text-2xl border-b p-4">
          {{ $t('MY_RESERVATIONS.ITEMS') }}
        </h2>
        <div v-for="item in reservation.requested_items" :key="item.id" class="p-4 border-b">
          <div class="flex flex-col md:flex-row items-center text-gray-800 font-semibold">
            <img :src="item.thumbnail_url" :alt="item.name" class="mr-5 left-0 rounded-md">
            <span class="mr-5 ml-5"> {{ item.request.amount }} x</span>
            <span class="mr-5 py-5">{{ item.name }} </span>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import Badge from "@/components/elements/ui/Badge.vue";
import Container from "@/components/elements/Container";
import ReservationRepository from "../repositories/ReservationRepository";

export default {
  name: "MyReservationDetails",
  components: {Badge, Container},

  props: {},
  data() {
    return {
      reservation: null
    }
  },
  methods: {
    getReservation() {
      ReservationRepository.getReservation(this.$route.params.id)
          .then((response => {
            this.reservation = response.data
          })).catch(() => {
      }).finally(
      );
    },
    printReservation(){

      ReservationRepository.printReservation(this.$route.params.id)
          .then((response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.reservation.room.name+'.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
          })).catch(() => {
      }).finally(
      );
    },

    cancelReservation(){

      ReservationRepository.cancelReservation(this.$route.params.id)
          .then((response => {
              this.reservation = response.data
          })).catch(() => {
      }).finally(
      );
    },


  },
  created() {
    this.getReservation()
  }
}
</script>

<style scoped>

</style>