<template>
  <div class="h-full flex flex-col bg-no-repeat bg-cover bg-fixed" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">
    <div class=" px-4 py-8  md:h-32  h-52 w-full  " >
      <page-heading title="" subtitle=""/>
    </div>

    <div class="flex-grow  w-full  pt-4">
      <container class="text-gray-700" :padding-top="false">
        <h2 class="text-2xl  font-bold text-center">FAQ</h2>
        <div class="md:w-2/3 m-auto" v-if="items">
          <faq-list-item class=" bg-white bg-opacity-70 shadow-md  my-5 rounded p-5 border-2 border-flamingo-800" v-for="item in items" :item="item"
                         :key="item.id"></faq-list-item>
        </div>
      </container>
    </div>
  </div>
</template>

<script>

import PageHeading from "@/components/elements/PageHeading";
import Container from "@/components/elements/Container";
import FaqListItem from "./elements/ui/FaqListItem";

export default {
  name: "Faq",
  components: {Container, PageHeading, FaqListItem},
  data() {
    return {
      opened: false,
      backgroundImagePath: require('@/assets/images/auth/bg-light.jpg'),

      items: [
        {
          id: 0,
          question: 'Question 1',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum ipsam, vel. Amet asperiores consectetur excepturi expedita iusto molestiae, nam placeat quidem quis similique sit ullam voluptatem'
        },
        {
          id: 1,
          question: 'Question 2',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum ipsam, vel. Amet asperiores consectetur excepturi expedita iusto molestiae, nam placeat quidem quis similique sit ullam voluptatem'
        },
        {
          id: 2,
          question: 'Question 3',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum ipsam, vel. Amet asperiores consectetur excepturi expedita iusto molestiae, nam placeat quidem quis similique sit ullam voluptatem'
        },
        {
          id: 3,
          question: 'Question 4',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum ipsam, vel. Amet asperiores consectetur excepturi expedita iusto molestiae, nam placeat quidem quis similique sit ullam voluptatem'
        }

      ]
    }
  },
}
</script>

<style scoped>

</style>