<template>
  <div>
    <div class="md:h-36 h-52 py-10 px-5  bg-no-repeat bg-cover" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">
      <page-heading :title="$t('PROFILE.TITLE')" :subtitle="$t('PROFILE.SUBTITLE')"/>
    </div>

    <div class=" w-full bg-flamingo-800   pt-4">
      <container :padding-top="false">
        <div class="flex flex-col">

          <div class="mx-auto -mt-28 rounded-full shadow-xl">
            <div class="h-48 w-48 rounded-full bg-olive-300">
              <img src="https://picsum.photos/200" alt="Lorem" class="rounded-full p-2">
            </div>
          </div>

<!--          lastname + firstname-->
          <div class="mx-auto text-center text-white text-4xl font-serif mt-6">
            <h1>
              <span class="uppercase">{{ user.last_name }}</span> <span style="text-transform: capitalize">{{ user.first_name }}</span>
            </h1>
            <div class="flex">

              <round-action-button :variant="editButtonVariant" :icon="editButtonIcon" class="mt-8 mx-auto inline-block"
                                   @click="closeEditProfile"></round-action-button>
              <round-action-button v-if="editMode" variant="success" icon="check" class="mt-8 mx-auto inline-block" @click="updateProfile"
              ></round-action-button>
            </div>
          </div>

<!--          incomplete profile-->
          <div class="grid grid-cols-1" v-if="!hasCompletedProfile">
            <div class="bg-red-400 p-4 mt-10 rounded-md font-serif text-red-900 flex items-center ring-2 ring-red-800 ring-inset shadow-md">
              <font-awesome-icon icon="exclamation-triangle" class="text-2xl flex-grow-0"/>
              <span class="text-center w-full">{{ $t('PROFILE.PLEASE_COMPLETE') }}</span>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16" :class="[!hasCompletedProfile ? 'mt-10' : 'mt-20']">

            <card title="Contact">
              <div class="flex flex-col space-y-4">

                <div class="sm:flex">
                  <div class="flex-1 flex">
                    <font-awesome-icon icon="envelope" class="text-flamingo-800 text-2xl"></font-awesome-icon>
                    <span class="text-flamingo-800 font-semibold ml-2 "> {{ $t('PROFILE.EMAIL') }}</span>
                  </div>
                  <span>{{ user.email }}</span>
                </div>

                <div class="sm:flex">
                  <div class="flex flex-1 sm:w-full md:w-1/5">
                    <font-awesome-icon icon="phone" class="text-flamingo-800 text-2xl "></font-awesome-icon>
                    <span class="text-flamingo-800 font-semibold ml-2  ">{{ $t('PROFILE.PHONE') }}</span>
                  </div>
                  <div v-if="editMode" class="flex ml-0 sm:w-full md:w-4/5">
                    <drop-down-element
                        class="w-2/4"
                        label-class="h-0"
                        :options="callingCodes"
                        :defaultValue="user.phone_country_id"
                        v-on:selected="validateCallingCodeSelection"
                        v-on:filter="getCallingCodeDropdownValues"
                        name="phone_country_id"
                        :maxItem="10"
                        :placeholder=" $t('PROFILE.INDICATIF')">
                    </drop-down-element>
                    <input-element name="phone_number" autocomplete="phone" class="w-2/4 pl-2" type="text" v-model="user.phone_number" label-class="h-0"
                                   @enter="updateProfile">{{ $t('PROFILE.PHONE') }}
                    </input-element>
                  </div>
                  <span class="" :class="{'italic text-red-600': !user.complet_phone_number}" v-else>{{
                      user.complet_phone_number || $t('PROFILE.INCOMPLETE')
                    }}</span>
                </div>

                <div class="sm:flex">
                  <div class="flex flex-1 sm:w-full md:w-1/5">
                    <font-awesome-icon icon="phone" class="text-flamingo-800 text-2xl "></font-awesome-icon>
                    <span class="text-flamingo-800 font-semibold ml-2  ">{{ $t('PROFILE.PHONE_OFFICE') }}</span>
                  </div>
                  <div v-if="editMode" class="flex ml-0 sm:w-full md:w-4/5">
                    <drop-down-element
                        class="w-2/4"
                        label-class="h-0"
                        :options="callingCodes"
                        :defaultValue="user.phone_office_country_id"
                        v-on:selected="validateCallingCodeSelection"
                        v-on:filter="getCallingCodeDropdownValues"
                        name="phone_office_country_id"
                        :maxItem="10"
                        :placeholder=" $t('PROFILE.INDICATIF')">
                    </drop-down-element>
                    <input-element name="phone_office_number" autocomplete="phone_office" class="w-2/4 pl-2" type="text" v-model="user.phone_number_office" label-class="h-0"
                                   @enter="updateProfile">{{ $t('PROFILE.PHONE_OFFICE') }}
                    </input-element>
                  </div>
                  <span class="" :class="{'italic text-red-600': !user.complet_phone_office_number}" v-else>{{
                      user.complet_phone_office_number || $t('PROFILE.INCOMPLETE')
                    }}</span>
                </div>
                <div class="sm:flex">
                  <div class="flex flex-1 sm:w-full md:w-1/5">
                    <font-awesome-icon icon="phone" class="text-flamingo-800 text-2xl "></font-awesome-icon>
                    <span class="text-flamingo-800 font-semibold ml-2  ">{{ $t('PROFILE.PHONE_FIX') }}</span>
                  </div>
                  <div v-if="editMode" class="flex ml-0 sm:w-full md:w-4/5">
                    <drop-down-element
                        class="w-2/4"
                        label-class="h-0"
                        :options="callingCodes"
                        :defaultValue="user.phone_fix_country_id"
                        v-on:selected="validateCallingCodeSelection"
                        v-on:filter="getCallingCodeDropdownValues"
                        name="phone_fix_country_id"
                        :maxItem="10"
                        :placeholder=" $t('PROFILE.INDICATIF')">
                    </drop-down-element>
                    <input-element name="phone_fix_number" autocomplete="phone_number_fix" class="w-2/4 pl-2" type="text" v-model="user.phone_number_fix" label-class="h-0"
                                   @enter="updateProfile">{{ $t('PROFILE.PHONE_FIX') }}
                    </input-element>
                  </div>
                  <span class="" :class="{'italic text-red-600': !user.complet_phone_fix_number}" v-else>{{
                      user.complet_phone_fix_number || $t('PROFILE.INCOMPLETE')
                    }}</span>
                </div>


              </div>
            </card>

            <card title="Adresse">
              <div class="flex flex-col space-y-4">

                <div class="sm:flex items-center justify-between">
                  <span class="block  text-flamingo-800 font-semibold" :class="editMode ? 'md:w-1/5' :''">
                    {{ $t('PROFILE.NUMBER') }}
                  </span>

                  <input-element name="number" autocomplete="number-address" class="sm:w-full md:w-4/5" type="text"
                                 v-model="user.number"
                                 label-class="h-0" @enter="updateProfile" v-if="editMode">{{ $t('PROFILE.NUMBER') }}
                  </input-element>
                  <span :class="{'italic text-red-600': !user.number}" v-else>{{ user.number || $t('PROFILE.INCOMPLETE') }}</span>
                </div>

                <div class="sm:flex items-center justify-between">
                  <span class="block  text-flamingo-800 font-semibold" :class="editMode ? 'md:w-1/5' :''">
                    {{ $t('PROFILE.STREET') }}
                  </span>

                  <input-element name="street" autocomplete="street-address" class="sm:w-full md:w-4/5" type="text"
                                 v-model="user.street"
                                 label-class="h-0" @enter="updateProfile" v-if="editMode">{{ $t('PROFILE.STREET') }}
                  </input-element>
                  <span :class="{'italic text-red-600': !user.street}" v-else>{{ user.street || $t('PROFILE.INCOMPLETE') }}</span>
                </div>

                <div class="sm:flex items-center justify-between">
                  <span class="block text-flamingo-800 font-semibold" :class="editMode ? 'md:w-1/5' :''">
                    {{ $t('PROFILE.CITY') }}
                  </span>

                  <input-element name="city" aurocomplete="address-level1" class="sm:w-full md:w-4/5" type="text" v-model="user.city"
                                 label-class="h-0"
                                 @enter="updateProfile" v-if="editMode"> {{ $t('PROFILE.CITY') }}
                  </input-element>
                  <span :class="{'italic text-red-600': !user.city}" v-else>{{ user.city || $t('PROFILE.INCOMPLETE') }}</span>
                </div>

                <div class="sm:flex items-center justify-between">
                  <span class="block text-flamingo-800 font-semibold" :class="editMode ? 'md:w-1/5' :''">
                    {{ $t('PROFILE.POSTAL_CODE') }}
                  </span>

                  <input-element name="postal_code" autocomplete="postal-code" class="sm:w-full md:w-4/5" type="text"
                                 v-model="user.postal_code" label-class="h-0"
                                 @enter="updateProfile" v-if="editMode"> {{ $t('PROFILE.POSTAL_CODE') }}
                  </input-element>
                  <span :class="{'italic text-red-600': !user.postal_code}" v-else>{{ user.postal_code || $t('PROFILE.INCOMPLETE') }}</span>
                </div>

                <div class="sm:flex items-center justify-between">
                  <span class="block text-flamingo-800 font-semibold" :class="editMode ? 'w-1/5' :''">
                    {{ $t('PROFILE.COUNTRY') }}
                  </span>
                  <drop-down-element
                      label-class="h-0"
                      class="sm:w-full md:w-4/5"
                      v-if="editMode"
                      :options="countries"
                      :defaultValue="user.country_id"
                      v-on:selected="validateSelection"
                      v-on:filter="getDropdownValues"
                      name="country"
                      :maxItem="10"
                      :placeholder=" $t('PROFILE.COUNTRY')">
                  </drop-down-element>
                  <span :class="{'italic text-red-600': !user.country_name}" v-else>{{ user.country_name || $t('PROFILE.INCOMPLETE') }}</span>
                </div>
              </div>
            </card>

            <card :title="$t('PROFILE.LIST')">



              <div class="flex flex-col space-y-4">

<!--                new profile-->
                <table style ="width: 100%" v-if="editMode">
                  <tr>
                    <td><b>Nom du profil</b></td>
                    <td>
                      <input-element name="profile_name" v-model="profile_name"  class="sm:w-full md:w-4/5" type="text"
                                     label-class="h-0">
                      </input-element>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Institution</b></td>
                    <td>
                      <input-element name="profile_institution" v-model="profile_institution"  class="sm:w-full md:w-4/5" type="text"
                                     label-class="h-0">
                      </input-element>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Fonction</b></td>
                    <td>
                      <input-element name="profile_role" v-model="profile_role"  class="sm:w-full md:w-4/5" type="text"
                                     label-class="h-0">
                      </input-element>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Type</b></td>
                    <td>
                      <select-element name="profil" class="sm:w-full md:w-4/5" v-model="profile_type">
                        <option :value="type.id" v-for="type in this.$store.state.customer_types" :key="type.id">
                          {{type.name_fr}}
                        </option>
                      </select-element>

                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td><br>
                      <button type="button"
                              @click="addProfile"
                              class="text-white mt-3 w-full inline-flex justify-center rounded-md border border-gray-300
                shadow-sm px-4 py-2 bg-green-500 text-base font-medium hover:bg-green-700
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Ajouter
                      </button>
                    </td>
                  </tr>
                </table>
<!--                new profile-->

                <ProfileListItem :mode="editMode"
                    class=" bg-white bg-opacity-70 shadow-md  my-5 rounded p-5 border-2 border-flamingo-800"
                    v-for="item in profiles"  @updateProfiles="getProfiles"  :item="item" :key="item.id" />
              </div>


            </card>

            <card :title="$t('PROFILE.PASSWORD')" :subtitle="$t('PROFILE.PASSWORD_SUBTITLE')" class="">
              <div class="space-y-4 flex flex-col">
                <div class="sm:flex sm:justify-between">
                  <span class="text-flamingo-800 font-semibold block" :class="editMode ? 'md:w-1/5' :''">{{ $t('REGISTER.PASSWORD') }}</span>
                  <input-element name="password" class="sm:w-full md:w-4/5" type="password"
                                 v-model="user.password"
                                 label-class="h-0"
                                 @enter="updateProfile" v-if="editMode">{{ $t('REGISTER.PASSWORD') }}
                  </input-element>
                </div>
                <div class="sm:flex sm:justify-between">
                  <span class="text-flamingo-800 font-semibold block" :class="editMode ? 'md:w-1/5' :''">{{ $t('REGISTER.PASSWORD_CONFIRM') }}</span>
                  <input-element name="password_confirmation" class="sm:w-full md:w-4/5" type="password"
                                 v-model="user.password_confirmation"
                                 label-class="h-0"
                                 @enter="updateProfile" v-if="editMode">{{ $t('REGISTER.PASSWORD_CONFIRM') }}
                  </input-element>
                </div>
              </div>
            </card>
          </div>
        </div>
      </container>
    </div>
  </div>
</template>

<script>
import PageHeading from "@/components/elements/PageHeading";
import Container from "@/components/elements/Container";
import Card from "@/components/elements/Card";
import RoundActionButton from "@/components/elements/forms/RoundActionButton";
import InputElement from "@/components/elements/forms/InputElement";
import DropDownElement from "./elements/forms/DropDownElement";
import ProfileListItem from "@/components/elements/ui/ProfileListItem";
import SelectElement from "@/components/elements/forms/SelectElement";

export default {
  name: "Profile",
  components: {SelectElement, ProfileListItem, InputElement, RoundActionButton, Card, Container, PageHeading, DropDownElement},
  data: function () {
    return {
      editMode: false,
      profileMode: false,
      user: '',
      countries: [],
      profiles: [],
      profile_name: '',
      profile_institution: '',
      profile_role: '',
      profile_type: '',
      backgroundImagePath: require('@/assets/images/auth/bg-light.jpg'),
      customer_types: null
    }
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('CLEAR_VALIDATION_ERRORS');
      this.$store.dispatch('CLEAR_ERROR_MESSAGE');

      this.$store.dispatch("EDIT_USER", {
        number: this.user.number,
        street: this.user.street,
        city: this.user.city,
        postal_code: this.user.postal_code,
        country: this.user.country_id,
        phone_country_id: this.user.phone_country_id,
        phone_fix_country_id: this.user.phone_fix_country_id,
        phone_office_country_id: this.user.phone_office_country_id,

        phone_number: this.user.phone_number,
        phone_number_office: this.user.phone_number_office,
        phone_number_fix: this.user.phone_number_fix,

        // bank_iban: this.user.bank_iban,
        password: this.user.password
      }).then(() => {
        this.editMode = false
        this.user.password = ''
        this.user.password_confirmation = ''
      }).catch(() => {
      })
    },
    addProfile() {
      this.$store.dispatch('CLEAR_VALIDATION_ERRORS');
      this.$store.dispatch('CLEAR_ERROR_MESSAGE');

      this.$store.dispatch("SAVE_PROFILE", {
        customer_type_id: this.profile_type,
        institution_name: this.profile_institution,
        role: this.profile_role,
        name: this.profile_name
      }).then(() => {
        console.log('success add profile')
        this.getProfiles();
        this.clearProfileForm();
      }).catch(() => {
      })
    },

    getCountries() {
      this.$store.dispatch('GET_COUNTRIES').then(
          this.countries = this.$store.getters.getCountries
      )
    },

    getProfiles() {
      this.$store.dispatch('GET_PROFILES').then(()=>{
        this.profiles = this.$store.getters.getProfiles;
      })
    },


    getAllProfiles() {
      this.$store.dispatch('GET_ALL_PROFILES').then(()=>{
        this.customer_types = this.$store.getters.getCustomerTypes;
      })
    },


    closeEditProfile() {
      this.editMode = !this.editMode
      this.$store.dispatch('CLEAR_VALIDATION_ERRORS');
      this.$store.dispatch('CLEAR_ERROR_MESSAGE');
      this.user.password = ''
      this.user.password_confirmation = ''
    },

    validateSelection(e) {
      if (e)
        this.user.country_id = e.id
      this.user.country_name = e.name
    },
    getDropdownValues() {
      //console.log(e)
    },
    validateCallingCodeSelection(e) {
      if (e)
        this.user.phone_country_id = e.id
      this.user.calling_code_name = e.name
    },
    getCallingCodeDropdownValues() {
    },

    clearProfileForm() {
      this.profile_role = '';
      this.profile_name = '';
      this.profile_institution = '';
    }
  },
  mounted() {
    this.user = this.$store.getters.user
    if (!this.$store.getters.getCountries)
      this.getCountries()
    this.countries = this.$store.getters.getCountries
    this.getProfiles();
    this.getAllProfiles();
  },
  computed: {
    togle() {
      if(this.profilMode)
        return false
      return true

    },

    hasCompletedProfile() {
      return this.$store.getters.hasCompletedProfile;
    },
    editButtonVariant() {
      return this.editMode ? 'danger' : 'secondary';
    },
    editButtonIcon() {
      return this.editMode ? 'times' : 'pen';
    },
    callingCodes() {
      return this.countries.map((e) => {
        return {id: e.id, name: e.name + ' +' + e.calling_code + ''}
      })
    }
  }
}
</script>

<style scoped>

</style>