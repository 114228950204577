<template>
  <div class="h-full flex flex-col ">
    <div class="h-52 md-36 py-10 px-5  bg-no-repeat bg-cover" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">
      <page-heading :title="$t('NAV.MY_RESERVATIONS')" :subtitle="$t('MY_RESERVATIONS.SUBTITLE')"/>
    </div>
    <div class="flex-grow w-full relative  bg-gray-200">
      <div class="z-30 absolute top-0 left-1/2 transform -translate-y-full -translate-x-1/2 ">
        <span
            :class=" active === 'future'
            ? ' pb-2 bg-gray-200 text-flamingo-800 border-t-2 border-l-2 border-r-2 border-flamingo-800'
            : ' pb-0.5 bg-white text-olive-300 border-t-2 border-l-2 border-r-2 border-olive-300'"
            @click="active = 'future'"
            class="whitespace-nowrap px-10  pt-2 mx-5 rounded-t-md font-semibold cursor-pointer transition-all">À Venir</span>
        <span
            :class=" active === 'past'
            ? '  pb-2 bg-gray-200 text-flamingo-800 border-t-2 border-l-2 border-r-2 border-flamingo-800'
            : ' pb-0.5 bg-white text-olive-300 border-t-2 bg-white border-l-2 border-r-2 border-olive-300'"
            @click="active = 'past'"
            class=" whitespace-nowrap px-10  pt-2 mx-5 rounded-t-md font-semibold cursor-pointer transition-all">Passé</span>
      </div>
      <div class="relative z-20 bg-gray-200 border-t-2 border-flamingo-800">
        <container class="" :padding-top="false">
          <div v-if="reservations">
            <div v-if="reservations.length > 0" class="mt-16 grid grid-cols-1 md:grid-cols-1 mt-16 gap-16">
              <list-reservation-card v-for="reservation of reservations" :key="reservation.id" :reservation="reservation"
                                @clicked-reservation="$router.push({ path: `/my-reservation/${reservation.id}` }) ">
              </list-reservation-card>
            </div>
            <p v-else class=" mt-10 bg-olive-100 p-5">{{ $t('MY_RESERVATIONS.EMPTY') }}</p>
          </div>
          <div v-else class=" animate-pulse grid grid-cols-1 md:grid-cols-2 mt-16 gap-16">
            <div v-for="index in 6" :key="index">
              <div class="border border-white shadow rounded-md p-4 ">
                <div class=" space-y-4 py-1">
                  <div class="h-4 bg-white rounded w-3/4"></div>
                  <div class="space-y-2">
                    <div class="h-4 bg-white rounded"></div>
                    <div class="h-4 bg-white rounded w-5/6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </container>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationRepository from "../repositories/ReservationRepository";
import PageHeading from "@/components/elements/PageHeading";
import Container from "@/components/elements/Container";
import ListReservationCard from "@/components/elements/reservation/ListReservationCard";

export default {
  name: "MyReservations",
  components: {ListReservationCard, PageHeading, Container},
  data() {
    return {
      reservations: null,
      active: 'future',
      backgroundImagePath: require('@/assets/images/auth/bg-light.jpg'),
    }
  },
  watch: {
    active: function () {
      this.getReservations()
    },
  },
  methods: {
    getReservations() {
      this.reservations = null
      this.$Progress.start();
      ReservationRepository.getUserReservations(this.active)
          .then((response => {
            this.$Progress.finish();
            console.log(response)
            this.reservations = response.data
          })).catch(() => {
      }).finally(
      );
    },
  },
  created() {
    this.getReservations()
  }
}
</script>

<style scoped>

</style>