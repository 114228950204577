<template>
  <div class="shadow-xl w-full relative overflow-hidden cursor-pointer transition-all hover:shadow-2xl" @click="$emit('clicked-reservation', reservation)">

    <div class="relative  p-6 bg-white  rounded-b z-20">
      <div class="flex justify-between items-center space-x-3">
        <h2 class="text-flamingo-800 font-semibold text-2xl">
          {{ reservation.room.name }}<br><font-awesome-icon icon="map-marker-alt" class="text-xl"></font-awesome-icon> {{ reservation.room.building.city }}
        </h2>

        <div class="flex-shrink-0 text-xl">
          <div class="flex items-center space-x-4 text-gray-600">
            <font-awesome-icon icon="calendar-alt" class="text-xl"></font-awesome-icon>
            <span>{{ reservation.date_localized }} </span>
          </div>
          <div class="flex items-center space-x-4 text-gray-600">
            <font-awesome-icon icon="clock" class="text-xl"></font-awesome-icon>
            <span> {{ reservation.time_from_localized }} - {{ reservation.time_to_localized }}</span>
          </div>
          <badge v-if="reservation.status_id == 1" class="rounded-2xl" variant="success">{{ $t('MY_RESERVATIONS.CONFIRMED') }}</badge>
          <badge v-else-if="reservation.status_id == 2" class="rounded-2xl" variant="failed">{{ $t('MY_RESERVATIONS.REJECTED') }}</badge>
          <badge v-else-if="reservation.status_id == 3" class="rounded-2xl" variant="failed">{{ $t('MY_RESERVATIONS.CANCELLED') }}</badge>
          <badge v-else class="rounded-2xl" variant="secondary">{{ $t('MY_RESERVATIONS.WAITING') }}</badge>
        </div>
      </div>

      <div class="h-0.5 w-full bg-gray-100 mt-6 mb-6"></div>

      <div class="flex flex-col md:flex-row md:space-x-8 mt-4 items-center">

        <div class="flex flex-col text-gray-600 md:space-y-2 w-full md:w-4/5">
          <div class="space-y-2">

            <div class="grid grid-cols-2 space-x-4">
              <div class="md:flex ">
                <span class="w-3/6 font-semibold">{{ $t('MY_RESERVATIONS.FOR') }} : </span>
                <span class="w-3/6">{{ reservation.title }}</span>
              </div>

            </div>

            <div class="grid grid-cols-2 space-x-4">
              <div v-if="reservation.description" class="md:flex ">
                <span class="w-3/6 font-semibold">{{ $t('MY_RESERVATIONS.DESCRIPTION') }} : </span>
                <span class="w-3/6">{{ reservation.description }}</span>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Badge from "@/components/elements/ui/Badge.vue";

export default {
  name: "ListReservationCard",
  components: {Badge},
  props: {
    reservation: {
      type: Object
    },
  }
}
</script>

<style scoped>

</style>