<template>
  <div class="text-gray-700 mt-5 w-full">
<!--    <select-element name="profil" class="mb-5" v-model="reservation.profil" :label="$t('MAKE_RESERVATION.PROFIL')"-->
<!--                    label-class="text-gray-800 text-xl mb-2">-->
<!--      <option :value="profile.id" v-for="profile in profiles" :key="profile.id">-->
<!--        {{profile.role}} - {{profile.institution_name}}-->
<!--      </option>-->
<!--    </select-element>-->

    <vueper-slides v-if="images&&images.length" class="reservation-slides">
      <vueper-slide
          v-for="(slide, i) in images"
          :key="`room-${i}`"
          :content="`<img class='col-span-1 rounded-t w-full h-full object-cover' src='${slide.image_url}' alt=''>`">
      </vueper-slide>
    </vueper-slides>

    <label class="block text-gray-800 text-xl mt-3 mb-2">
      <span> {{ $t('MAKE_RESERVATION.PROFIL') }} </span>
    </label>
    <h3 class="mb-3">{{profile.role}} - {{profile.institution_name}}</h3>


    <date-time-picker-element
        name="date"
        validationName="date"
        icon="calendar-alt"
        label-class="text-gray-800 text-xl mb-2"
        :min-date="getMinDate"
        :only-date="true"
        :format="'DD.MM.YYYY'"
        v-model="reservation.date"
        class="mb-5"
    >
      Date
    </date-time-picker-element>
    <div class="grid grid-cols-1 md: grid-cols-2 gap-4">
      <date-time-picker-element
          name="from-"
          validationName="start_date"
          icon="calendar-alt"
          label-class="text-gray-800 text-xl mb-2"
          :min-date="getMinDate"
          :only-time="true"
          :format="'HH:mm'"
          v-model="reservation.startTime"
          class="mb-5"
      >
        De
      </date-time-picker-element>
      <date-time-picker-element
          name="to-"
          validationName="end_date"
          icon="calendar-alt"
          label-class="text-gray-800 text-xl mb-2"
          :min-date="getMinDate"
          :only-time="true"
          :format="'HH:mm'"
          v-model="reservation.endTime"
          class="mb-5"
      >
        A
      </date-time-picker-element>
    </div>
    <input-element name="title" class="mb-5" type="text" v-model="reservation.title"
                   label-class="text-gray-800 text-xl mb-2"
                   @enter="nextStep">{{ $t('MAKE_RESERVATION.NAME') }}
    </input-element>

    <select-element v-if="reservation.date" name="recurrence" class="mb-5" v-model="reservation.recurrence" :label="$t('MAKE_RESERVATION.RECURRENCE.REPEAT')"
                    label-class="text-gray-800 text-xl mb-2">
      <option :value="type.value" v-for="(type ,index) in recurrenceTypes" :key="index">
        <localized-recurrence-dates :date="reservation.date" :type="type.key"></localized-recurrence-dates>
      </option>
    </select-element>

    <checkbox-element v-if="reservation.recurrence !== 'none'" :name="$t('MAKE_RESERVATION.RECURRENCE.NOT_DURING_VACATION')"
                      v-model="reservation.skip_vacations"></checkbox-element>

    <date-time-picker-element
        v-if="reservation.recurrence !== 'none'"
        name="recurrence_end"
        icon="calendar-alt"
        label-class="text-gray-800 text-xl mb-2"
        :min-date="getRecurrenceMinDate"
        :only-date="true"
        :format="'DD.MM.YYYY'"
        v-model="reservation.recurrence_end"
        class="mb-5"
    >
      {{ $t('MAKE_RESERVATION.RECURRENCE.EVENT_ENDS') }}
    </date-time-picker-element>

    <div v-if="reservation.options.length">
      <label class="block text-gray-800 text-xl mb-2">
        <span class="">Options</span>
      </label>
       <div>
         <div
             class="bg-white rounded-md border-2 shadow-sm flex flex-col md:flex-row items-center justify-between cursor-pointer p-5 mb-5 transition hover:shadow-lg"
             :class="opt.selected ? 'border-2 border-flamingo-800' : ''"
             v-for="(opt, optIndex) in reservation.options" :key="`opt-${optIndex}`" @click.self="clickedOpt(optIndex)">
           <div class="flex items-center justify-between">
             <custom-checkbox-element :id="optIndex" :selected="opt.selected" v-model="opt.selected" @checkbox-clicked="clickedOpt(optIndex)"></custom-checkbox-element>
             <p class="m-3 text-center cursor-default flex flex-col">
               <span class="font-bold">{{ opt.name }} </span>
             </p>

           </div>
           <div class="flex items-center">
           <number-selector-element v-if="opt.type=='countable'" label-class="hidden" class="m-5" :name="'amount' + optIndex " v-model="opt.count" :unsigned="true">
             {{ $t('MAKE_RESERVATION.AMOUNT') }}
           </number-selector-element>
             <div class="mr-11">
               {{opt.pivot.price}} (€)
             </div>
           </div>
         </div>
       </div>
    </div>

    <text-area-element name="description" class="mb-5 mt-3" type="text" v-model="reservation.description"
                   label-class="text-gray-800 text-xl mb-2"
                   @enter="nextStep">{{ $t('MAKE_RESERVATION.DESCRIPTION') }}
    </text-area-element>
    <text-area-element name="comment" class="mb-5" type="text" v-model="reservation.comment"
                   label-class="text-gray-800 text-xl mb-2"
                   @enter="nextStep">{{ $t('MAKE_RESERVATION.COMMENT') }}
    </text-area-element>

    <p class="bg-olive-100 p-5">{{ $t('MAKE_RESERVATION.NO_ENGAGEMENT') }}</p>


  </div>
</template>

<script>
import InputElement from "@/components/elements/forms/InputElement";
import SelectElement from "../forms/SelectElement";
import CheckboxElement from "../forms/CheckboxElement";
import TextAreaElement from "../forms/TextAreaElement";
import DateTimePickerElement from "@/components/elements/forms/DateTimePickerElement";
import LocalizedRecurrenceDates from "./LocalizedRecurrenceDates";
import ReservationRepository from "@/repositories/ReservationRepository";
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import NumberSelectorElement from "../forms/NumberSelectorElement";
import CustomCheckboxElement from "../forms/CustomCheckboxElement";

export default {
  name: "MakeReservationFirstStep",
  components: {
    InputElement,
    SelectElement,
    CheckboxElement,
    DateTimePickerElement,
    LocalizedRecurrenceDates,
    TextAreaElement,
    VueperSlides,
    VueperSlide,
    CustomCheckboxElement,
    NumberSelectorElement,
  },
  props: {
    reservation: {
      type: Object,
      required: true
    },
    room:null,
    images:[]
  },
  data() {
    return {
      recurrenceTypes: [
        {value: 'none', key: 'ONCE'},
        {value: 'daily', key: 'EVERY_DAY'},
        {value: 'weekly', key: 'EVERY_WEEK'},
        {value: 'monthly', key: 'EVERY_MONTH'},
        {value: 'yearly', key: 'EVERY_YEAR'},
        {value: 'every_week_day', key: 'EVERY_WEEK_DAY'}
      ],
      profiles: [],
      profile: []
    }
  },
  methods: {
    nextStep() {
      this.$emit.nextStep()
    },

    getProfiles() {
      this.$store.dispatch('GET_PROFILES').then(()=>{
        this.profiles = this.$store.state.profiles;
        this.profile = this.profiles.find(item => item.id = localStorage.profile);
      })
    },
    getOptions() {
      ReservationRepository.getOptions( this.reservation.profile, this.room).then((res)=>{
        this.reservation.options = res.data.options;
      })
    },
    clickedOpt(index){
      let opt = this.reservation.options[index];
      this.$set(this.reservation.options[index], 'selected', ! opt.selected);
    }
  },
  mounted() {
    console.log(this.images, "imeges");
    if(localStorage.profile){
      this.reservation.profile = localStorage.profile;
    }
    this.getProfiles();
  },
  watch:{
    "reservation.profile": function (val){
       if(val){
         this.getOptions();
       }
    }
  },

  computed: {
    getMinDate() {
      return this.$dayjs().add(7, 'day').hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm');

    },
    getRecurrenceMinDate() {
      return this.$dayjs(this.reservation.date , 'DD.MM.YYYY').add(1,'day').format('YYYY-MM-DD');
    },
  }
}
</script>

<style>

.date-time-picker {
  font-family: inherit !important;
  margin-bottom: 1.25rem;
}

.reservation-slides .vueperslides__inner,
.reservation-slides .vueperslides__parallax-wrapper,
.reservation-slides .vueperslide__content,
.reservation-slides .vueperslides__track
{
  max-height: 450px;
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
  box-shadow: none;
}
</style>