<template>
  <div class=" m-5 transition-all text-gray-600 overflow-hidden">
    <div  class="flex items-center justify-between">
      <h4 :class="open ? 'text-flamingo-800' : ''">{{ item.name }}</h4>
      <font-awesome-icon
          icon="chevron-down" class=" transition-all transform  cursor-pointer"
          :class=" open ? 'rotate-180' : ''"
          key="static-icon"   @click.prevent="openAccordion">

      </font-awesome-icon>
    </div>
    <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end">
      <p class="" v-show="open">
        <table style ="width: 100%">
        <tr>
          <td><b>Nom du profil</b></td>
          <td v-if="mode">
            <input-element name="name" v-model="profile.name"  class="sm:w-full md:w-4/5" type="text"
                           label-class="h-0">{{ item.name }}
            </input-element>
          </td>
          <td v-else>{{ item.name }}</td>
        </tr>
        <tr>
          <td><b>Institution</b></td>
          <td v-if="mode">
            <input-element name="institution" v-model="profile.institution"  class="sm:w-full md:w-4/5" type="text"
                           label-class="h-0">{{ item.institution_name }}
            </input-element>
          </td>
          <td v-else>{{ item.institution_name }}</td>
        </tr>
        <tr>
          <td><b>Fonction</b></td>
          <td v-if="mode">
            <input-element name="role" v-model="profile.role"  class="sm:w-full md:w-4/5" type="text"
                           label-class="h-0">{{ item.role }}
            </input-element>
          </td>
          <td class="lm-text-right" v-else>{{ item.role }}</td>
        </tr>
        <tr>
          <td><b>Type</b></td>
          <td>{{ item.customer_type.name_fr }}</td>
        </tr>
      <tr>
        <td></td>
        <td v-if="mode"><br>
          <button type="button"
                  @click="updateProfile"
                  class="text-white mt-3 w-full inline-flex justify-center rounded-md border border-gray-300
                shadow-sm px-4 py-2 bg-green-500 text-base font-medium hover:bg-green-700
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Enregistrer
          </button>
          <button type="button"
                  @click="deleteProfile"
                  class="text-white mt-3 w-full inline-flex justify-center rounded-md border border-gray-300
                shadow-sm px-4 py-2 bg-red-500 text-base font-medium hover:bg-red-700
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Supprimer
          </button>
        </td>
      </tr>
      </table>

      </p>
    </transition>
  </div>
</template>

<script>
import InputElement from "@/components/elements/forms/InputElement";
export default {
  name: "ProfileListItem",
  data() {
    return {
      open: false,
      profile: {
        role: this.item.role,
        institution: this.item.institution_name,
        name: this.item.name,
        customer_type_id:'',
        customer_id: '',
        id: this.item.id
      }
    }
  },
  components: {InputElement},
  props: {
    mode: {
      required: true,
      type: Boolean
    },
    item: {
      required: true,
      type: Object
    },
  },

  methods: {
    openAccordion() {
      this.$parent.$children.forEach((item) => {
        if(item.item){
          if (item.item.id === this.item.id) item.open = !item.open
          else item.open = false
        }
      })
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
    updateProfile() {
      this.$store.dispatch("UPDATE_PROFILE", {
        name: this.profile.name,
        role: this.profile.role,
        institution: this.profile.institution,
        profile_id: this.profile.id,
      }).then(() => {
        this.openAccordion();
        this.$emit('updateProfiles');
      }).catch(() => {
      })
    },
    deleteProfile() {
      this.$store.dispatch("DELETE_PROFILE", this.profile.id).then(() => {
        this.openAccordion();
        this.$emit('updateProfiles');
      }).catch(() => {
      })
    },


  },
  computed: {},
  mounted() {
  }
}
</script>

<style lang="scss" scoped>


.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>