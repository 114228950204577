<template>
  <VueCtkDateTimePicker :no-value-to-custom-elem="true" v-model="content"
                        color="#724387" button-color="#f2b324" locale="fr"
                        :format="format" :minute-interval="5"
                        :min-date="minDate"
                        :max-date="maxDate"
                        :only-date="onlyDate"
                        :only-time="onlyTime"
                        :disabled-hours="['00','01','02','03','04','05','06','07']"
                        :button-now-translation="$t('ELEMENTS.DATEPICKER_BUTTON_NOW')">
    <input-element :name="name"
                   :icon="icon"
                   type="text"
                   :label-class="labelClass"
                   v-model="content"
                   @input="setValue(content)"
                   @enter="$emit('enter')">
      <slot></slot>
    </input-element>
    <div class="text-red-600 text-xs" v-show="hasError">
      {{ getFirstErrorMessage }}
    </div>
  </VueCtkDateTimePicker>
</template>

<script>
import InputElement from "@/components/elements/forms/InputElement";

export default {
  name: "DateTimePickerElement",
  components: {InputElement},
  data: () => ({
    content: '',
    placeholder: ''
  }),
  props: {
    value: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    validationName: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false
    },
    /**
     * Must be in format:
     * YYYY-MM-DD HH:mm
     *
     * If set, users can not select a date prior to this.
     */
    minDate: {
      type: String,
      required: false
    },
    /**
     * Must be in format:
     * YYYY-MM-DD HH:mm
     *
     * If set, users can not select a date after this.
     */
    maxDate: {
      type: String,
      required: false
    },
    onlyDate: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyTime: {
      type: Boolean,
      required: false,
      default: false
    },
    format: {
      type: String,
      required: false,
      default: "DD.MM.YYYY HH:mm"
    }
  },
  watch: {
    value: function (val) {
      this.setValue(val);
    }
  },
  computed: {
    /**
     * Returns the name that is used to send the value to the server.
     * This can be useful for checking for validation errors.
     */
    getValidationName() {
      if(this.validationName !== undefined)
        return this.validationName;

      return this.name.toLowerCase().replace(' ', '_');
    },
    validationErrors() {
      return this.$store.getters.getValidationErrors;
    },
    hasError() {
      return this.getValidationName in this.validationErrors;
    },
    getFirstErrorMessage() {
      if(!this.hasError)
        return "";

      return this.validationErrors[this.getValidationName][0];
    }
  },
  methods: {
    setValue(val) {
      this.content = val;
      this.$emit('input', val)
    },
    defaultValue() {
      if (this.value)
        this.content = this.value
    }
  },
  mounted() {
    this.defaultValue()
  }
}
</script>

<style scoped>

</style>