<template>
  <div class="shadow-xl w-full relative overflow-hidden">
    <!-- Header -->

<!--    <img v-if="room.images[0]" class="rounded-t h-52 w-full object-cover" :src="room.images[0].image_url" alt="">-->

    <vueper-slides v-if="room.images&&room.images.length" class="reservation-grid-slides">
      <vueper-slide
          v-for="(slide, i) in room.images"
          :key="`grid-${i}`"
          :content="`<img class='col-span-1 rounded-t w-full h-full object-cover' src='${slide.image_url}' alt=''>`">
      </vueper-slide>
    </vueper-slides>

    <div class="relative p-6 bg-white  rounded-b z-20" :class="room.image_url ? 'mt-40 md:mt-56' : 'rounded-t' ">
      <div class="flex justify-between items-center space-x-3">
        <h2 class="text-flamingo-800 font-semibold text-2xl">
          {{ room.name }}<br><font-awesome-icon icon="map-marker-alt" class="text-xl"></font-awesome-icon> {{ room.building.name }}
        </h2>

        <div class="flex-shrink-0 text-xl">
          <font-awesome-icon icon="users"></font-awesome-icon>
          <span class="ml-2">{{ room.limit_of_persons }}</span>
        </div>
      </div>
      <div class="flex flex-col mt-4 text-gray-600 space-y-2">
        <div class="flex items-center space-x-4">

          <span>{{ room.building.city }}</span>
        </div>

<!--        <div class="flex items-center space-x-4">-->
<!--          <font-awesome-icon icon="calendar-alt" class="text-xl"></font-awesome-icon>-->
<!--          <span>06.05.2021 14:21 - 06.05.2021 14:21</span>-->
<!--        </div>-->
      </div>

      <div class="h-0.5 w-full bg-gray-100 mt-6 mb-6"></div>

      <div class="flex flex-col md:flex-row md:space-x-8 mt-4 items-center">

        <div class="flex flex-col text-gray-600 md:space-y-2 w-full md:w-4/5">
          <div class="space-y-2">

            <div class="grid grid-cols-2 space-x-4">
              <span class="font-serif">Prix de réservation</span>
              <badge variant="primary" v-if="room.booking_info.pricing_type === 'free'">Gratuit</badge>
              <badge variant="primary" v-else>{{ room.booking_info.price }}€ / {{ getBookingPricingType }}</badge>

            </div>

            <div class="grid grid-cols-2 space-x-4">
              <span class="font-serif">Dépot</span>
              <badge variant="secondary" v-if="room.booking_info.deposit_needed === 0">Aucun dépot requis</badge>
              <badge variant="secondary" v-else>{{ room.booking_info.deposit_amount }}€</badge>

            </div>
          </div>
        </div>

        <div class=" mt-6 md:mt-0 md:w-1/5 text-center">
          <round-action-button variant="primary" icon="plus" ref="searchButton" @click="$emit('make-reservation' , room)"></round-action-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Badge from "@/components/elements/ui/Badge.vue";
import RoundActionButton from "@/components/elements/forms/RoundActionButton.vue";
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  name: "AvailableRoomCard",
  components: {RoundActionButton, Badge, VueperSlides, VueperSlide},
  props: {
    room: {
      type: Object,
      required: true
    },
  },
  computed: {
    getBookingPricingType() {
      if (this.room.booking_info.pricing_type === 'per_day')
        return this.$t('NEW_RESERVATION.PER_DAY')
      else if (this.room.booking_info.pricing_type === 'per_hour')
        return this.$t('NEW_RESERVATION.PER_HOUR')
      else if (this.room.booking_info.pricing_type === 'per_reservation')
        return this.$t('NEW_RESERVATION.PER_RESERVATION')
      return ''
    }
  }
}
</script>

<style>

.reservation-grid-slides .vueperslides__inner,
.reservation-grid-slides .vueperslides__parallax-wrapper,
.reservation-grid-slides .vueperslide__content{
  height: 100%;
  width: 100%;
}
.reservation-grid-slides  .vueperslides__arrow svg{
  width: 45px;
}

</style>