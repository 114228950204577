<template>
  <container :padding-top="false">
    <div class="px-10">
      <div class="bg-flamingo-800 w-full rounded-lg -mt-20 p-8">
        <div class="grid grid-cols-1 md:grid-cols-9 gap-10">
          <date-time-picker-element
              name="from"
              icon="calendar-alt"
              label-class="text-white text-xl mb-2"
              :min-date="getDateSevenDaysFromNow"
              :max-date="formattedEndDate"
              :only-date="true"
              format="DD.MM.YYYY"
              v-model="date_start" class="md:col-span-2">
            {{ $t('NEW_RESERVATION.DATE') }}
          </date-time-picker-element>

          <date-time-picker-element
              name="from"
              icon="clock"
              label-class="text-white text-xl mb-2"
              :min-date="formattedStartDate "
              :only-time="true"
              format="HH:mm"
              v-model="from" class="md:col-span-2">
            {{ $t('NEW_RESERVATION.FROM') }}
          </date-time-picker-element>

          <date-time-picker-element
              name="to"
              icon="clock"
              label-class="text-white text-xl mb-2"
              :min-date="formattedStartDate "
              :only-time="true"
              format="HH:mm"
              v-model="to" class="md:col-span-2">
            {{ $t('NEW_RESERVATION.TO') }}
          </date-time-picker-element>

          <number-selector-element name="amount_of_people" icon="user" label-class="text-white text-xl mb-2" :unsigned="true" v-model="amount_of_people"
                                   class="md:col-span-2">
            {{ $t('NEW_RESERVATION.AMOUNT_PERSONS') }}
          </number-selector-element>



          <div class="text-center w-full">
            <round-action-button variant="secondary" icon="search" ref="searchButton" @click="search"></round-action-button>
          </div>
        </div>
        <div class="mt-3">
          <select-element name="profile" class="mb-5" v-model="profile" :label="$t('MAKE_RESERVATION.PROFIL')"
                          label-class="block text-white text-xl mb-2">
            <option :value="profile.id" v-for="profile in profiles" :key="profile.id">
              {{profile.role}} - {{profile.institution_name}}
            </option>
          </select-element>
        </div>
      </div>
    </div>

    <div class="px-10">
      <div class="flex justify-end mt-5">
          <font-awesome-icon @click="gridview" :class="[viewLayout=='grid' ? 'text-manatee-500' : 'text-manatee-400']" class="font-serif rounded-full  text-xl cursor-pointer  mr-3" icon="border-all" />
          <font-awesome-icon @click="listview" :class="[viewLayout=='list' ? 'text-manatee-500' : 'text-manatee-400']" class="font-serif rounded-full text-xl  cursor-pointer" icon="list" />
      </div>



      <div class="grid grid-cols-1 lg:grid-cols-2 mt-16 gap-16" v-if="this.viewLayout=='grid'">
        <available-room-card v-for="room of rooms" :key="room.id" :room="room" @make-reservation="makeReservation"></available-room-card>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-1 mt-16 gap-16" v-else>
        <list-room-card v-for="room of rooms" :key="room.id" :room="room" @make-reservation="makeReservation"></list-room-card>
      </div>

    </div>
  </container>
</template>

<script>
import Container from "@/components/elements/Container";
import DateTimePickerElement from "@/components/elements/forms/DateTimePickerElement";
import NumberSelectorElement from "@/components/elements/forms/NumberSelectorElement.vue";
import RoundActionButton from "@/components/elements/forms/RoundActionButton.vue";
import AvailableRoomCard from "@/components/elements/reservation/AvailableRoomCard.vue";
import ReservationRepository from "../repositories/ReservationRepository";
import ListRoomCard from "@/components/elements/reservation/ListRoomCard";
import SelectElement from "@/components/elements/forms/SelectElement";

export default {
  name: "DateView",
  components: {
    AvailableRoomCard, RoundActionButton, NumberSelectorElement, DateTimePickerElement, Container, ListRoomCard, SelectElement
  },

  mounted() {
    this.getProfiles();
  },


  data: () => ({
    date_start: null,
    date_end: null,
    from: null,
    to: null,
    amount_of_people: 10,
    rooms: null,
    viewLayout: 'grid',
    profiles: [],
    profile: null,
  }),
  watch:{

    profiles: function(val){
      if (val){
        this.profile = this.profiles[0].id;
        this.profileStorage()
        this.search();
      }
    },
    profile: function (val){
      if(val){
        this.profileStorage();
        this.search();
      }
    }
  },
  methods: {
    getProfiles() {
      this.$store.dispatch('GET_PROFILES').then(()=>{
        this.profiles = this.$store.state.profiles;
      })
    },
    gridview(){
      this.viewLayout='grid'
    },

    listview(){
      this.viewLayout='list'
    },

    search() {
      console.log('search---->');
      this.$refs.searchButton.toggleLoading();
      this.$store.dispatch('CLEAR_VALIDATION_ERRORS');
      this.$store.dispatch('CLEAR_ERROR_MESSAGE');
      this.$Progress.start();

      ReservationRepository.getReservations(this.date_start + ' ' + this.from, this.date_start + ' ' + this.to, this.amount_of_people, this.profile)
          .then((response => {
            this.$Progress.finish();
            this.rooms = response.data
          })).catch(() => {
        this.$Progress.fail();
      }).finally(
          this.$refs.searchButton.toggleLoading(),
      );
    },

    makeReservation(room) {
      this.$store.commit('SET_ROOM', room)
      this.$store.commit('SET_DATE', {date: this.date_start, timeStart: this.from, timeEnd: this.to})
      this.$router.push({name: 'create-reservation', params: {roomId: room.id}})
    },
    profileStorage(){
      localStorage.profile = this.profile;
    }
  },
  computed: {
    formattedStartDate() {
      if (this.date_start === null)
        return null;

      return this.$dayjs(this.date_start, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm');
    },
    formattedEndDate() {
      if (this.date_end === null)
        return null;

      return this.$dayjs(this.date_end, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm');
    },
    getDateSevenDaysFromNow() {
      return this.$dayjs().add(7, 'day').hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm');
    }
  }
}
</script>

<style scoped>

</style>