<template>
  <div>
    <div class="relative">
      <div class="p-2 rounded-full  md:flex justify-center bg-flamingo-800 text-white text-xl mt-5 relative top-6 ">
        <p class="md:absolute block text-center left-3 font-bold ">01</p>
        <p class="block text-center">{{ $t('MAKE_RESERVATION.DEMAND') }}</p>
      </div>
      <div class="bg-white p-5 pt-10 shadow-md rounded-b-lg">
        <div class="mb-5">
          <span class="block ">{{ $t('MAKE_RESERVATION.NAME') }}</span>
          <span class="text-gray-600"> {{ reservation.title }} </span>
        </div>
        <div class="mb-5">
          <span class="block ">{{ $t('MAKE_RESERVATION.RECURRENCE.REPEAT') }}</span>
          <div class="text-gray-600">
            <localized-recurrence-dates :date="reservation.date" :type="recurrenceType"></localized-recurrence-dates>
          </div>
        </div>
        <div v-if="reservation.recurrence !== 'none'" class="mb-5">
          <span class="block ">{{ $t('MAKE_RESERVATION.RECURRENCE.EVENT_ENDS') }}</span>
          <span class="text-gray-600"> {{ reservation.recurrence_end }} </span>
        </div>
        <div v-if="reservation.description" class="mb-5">
          <span class="block ">{{ $t('MAKE_RESERVATION.DESCRIPTION') }}</span>
          <span class="text-gray-600"> {{ reservation.description }} </span>
        </div>
        <div v-if="reservation.comment" class="mb-5">
          <span class="block ">{{ $t('MAKE_RESERVATION.COMMENT') }}</span>
          <span class="text-gray-600"> {{ reservation.comment }} </span>
        </div>

        <div>
          <h3>Options</h3>
          <div v-for="(opt, optIndex) in reservation.options" :key="`opt-${optIndex}`"  class="mb-5 mt-2">
            <template v-if="opt.selected">
                <span >{{opt.name}}</span>
                <span class="text-gray-600"> {{  opt.pivot.price }} (€) </span>
                <span v-if="opt.type==='countable'" class="mb-2 ml-5 text-gray-600"> {{ $t('MAKE_RESERVATION.AMOUNT') }}  {{  opt.count }} </span>
            </template>
          </div>
        </div>

      </div>
    </div>
    <div v-if="selectedItems.length > 0" class="relative">
      <div class="p-2 rounded-full md:flex justify-center bg-flamingo-800 text-white text-xl mt-5 relative top-6 ">
        <span class="md:absolute block text-center left-3 font-bold ">02</span>
        <span class="block text-center"> {{ $t('MAKE_RESERVATION.RENTAL') }}</span>
      </div>
      <div class="bg-white p-5 pt-10 shadow-md rounded-b-lg ">
        <div class="my-8 bg-manatee-200 flex flex-col md:flex-row items-center relative rounded-md shadow-sm mx-10" v-for="item of selectedItems"
             :key="item.id">
          <img :src="item.thumbnail_url" :alt="item.name" class="md-mr-5 left-0 rounded-md  ">
          <span class="md:mr-5 md:ml-5"> {{ item.amount }} x</span>
          <span class="md:mr-5 md:py-5">{{ item.name }} </span>
        </div>
      </div>
    </div>
    <div class="bg-white p-5 mt-5 shadow-md rounded-b-lg">
      <span>Total price</span>
      <span class="text-gray-600"> {{  totalPrice }} (€) </span>
    </div>
  </div>
</template>

<script>
import LocalizedRecurrenceDates from "./LocalizedRecurrenceDates";

export default {
  name: "MakeReservationFinalStep",
  components: {LocalizedRecurrenceDates},
  props: {
    reservation: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true,
    },
    room: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      recurrenceTypes: [
        {value: 'none', key: 'ONCE'},
        {value: 'daily', key: 'EVERY_DAY'},
        {value: 'weekly', key: 'EVERY_WEEK'},
        {value: 'monthly', key: 'EVERY_MONTH'},
        {value: 'yearly', key: 'EVERY_YEAR'},
        {value: 'every_week_day', key: 'EVERY_WEEK_DAY'}
      ],
    }
  },
  computed: {
    selectedItems() {
      return this.items.filter(item => {
        return item.selected
      })
    },
    recurrenceType() {
      return this.recurrenceTypes.find(type => type.value === this.reservation.recurrence).key
    },
    totalPrice(){
      let totalPrice = this.room.booking_info.price + this.room.booking_info.deposit_amount;
      for ( let opt of this.reservation.options){
        if(opt.selected){

          if(opt.type==="countable"){
            totalPrice += opt.pivot.price*opt.count;
          } else {
            totalPrice += opt.pivot.price;
          }
        }
      }
      return totalPrice;
    }
  },
  watch: {
  },
  methods:{
  },
  mounted() {
  },


}
</script>

<style scoped>

</style>